const spaceInfo = {
    1: {
        title: "북촌 너븐숭이"
    },
    2: {
        title: "낙선동 4·3 성"
    },
    3: {
        title: "다랑쉬굴"
    },
    4: {
        title: "주정공장 옛터"
    },
    5: {
        title: "곤을동 잃어버린 마을"
    },
    6: {
        title: "백조일손지지/섯알오름/만벵듸"
    },
}

export default spaceInfo;