<template>
    <div class="md-layout">
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-blue">
                <div class="card-icon">
                    <md-icon>description</md-icon>
                </div>
                <h4 class="title">{{spaceInfo[number].title}} Docent</h4>
                </md-card-header>
                <md-card-content>
                    <EditDocent v-if="docent" :docent="docent" />
                </md-card-content>
            </md-card>
        </div>
        <div class="md-layout-item md-size-100">
            <md-card>
                <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>info</md-icon>
                </div>
                <h4 class="title">{{spaceInfo[number].title}} Info</h4>
                </md-card-header>
                <md-card-content>
                <md-table v-model="contents" table-header-color="green">
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                        <md-table-cell md-label="Num">{{ "Content_" + Number(item.number + 1) }}</md-table-cell>
                        <md-table-cell md-label="Thumbnail">
                            <img class="item-thumb" :src="item.thumbnailUrl" alt="..." />
                        </md-table-cell>
                        <md-table-cell md-label="Data Type">{{ item.dataType }}</md-table-cell>
                        <md-table-cell md-label="Title">{{ item.dataTitle }}</md-table-cell>
                        <md-table-cell md-label="Language">{{
                            item.subLang ? "ko,"+Object.keys(item.subLang).toString() : 'ko'
                        }}</md-table-cell>
                        <md-table-cell md-label="Edit">
                            <a href="javascript:void(0);" @click="goToEdit(item.sceneId, item.number)" class="editBtn">edit</a>
                        </md-table-cell>
                    </md-table-row>
                </md-table>
                </md-card-content>
            </md-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import api from "@/commons/apiUrl";
import spaceInfo from "@/commons/spaceInfo";
import EditDocent from "@/components/Docent/EditDocent.vue";

export default {
    components: {
        EditDocent
    },
    data() {
        return {
            contents: {},
            docent: {},
            number: 1,
            spaceInfo
        }
    },
    watch: {
        async $route(to, form) {
            if (to.path !== form.path) {
                this.dataSet();
            }
        },
    },
    async created() {
        this.dataSet();
    },
    methods: {
        goToEdit(sceneId, number) {
            this.$router.push(`/edit/scene/${sceneId}/${number}`);
        },
        async dataSet() {
            const {number} = this.$route.params;

            // inject title
            this.number = Number(number);

            const res = await axios.get(api.GET_DATA(`space${number}`));
            if(!res.data.success) {
                return;
            }

            res.data.data.contents.forEach((element, i) => {
                element.thumbnailUrl = process.env.VUE_APP_API_BASE_URL + "/" + element.thumbnailUrl;
            });
            this.contents = res.data.data.contents;

            this.docent = res.data.data.docent;
        }
    }
}
</script>

<style scoped>
.item-thumb {
    max-width: 50px;
}
</style>